<template>
  <div>
    <div>
      <vs-col vs-type="flex" vs-justify="flex-end">
        <vs-row>
          <vs-button
            class="m-1 mb-4"
            color="success"
            type="filled"
            icon-pack="feather"
            icon="icon-plus"
            to="/formulario-de-ingreso-de-proyecto"
            >Proyecto</vs-button
          >
        </vs-row>
        <vs-tabs alignment="right" color="primary" class="p-1">
          <vs-tab
            @click="ShowCards = true"
            label="Tarjetas"
            icon-pack="feather"
            icon="icon-grid"
          ></vs-tab>
          <vs-tab
            @click="ShowCards = false"
            selected="true"
            label="Lista"
            icon-pack="feather"
            icon="icon-list"
          ></vs-tab>
        </vs-tabs>
      </vs-col>
    </div>
    <br />
    <br />
    <br />
    <br />
    <div>
      <!-- CONTENT CARD - PROGRESS BAR/GOAL -->
      <div v-show="ShowCards">
        <div class="flex flex-wrap sm:full lg:w-full mt-1">
          <div
            class="w-full sm:w-1/2 md:w-3/3 lg:w-1/5 xl:w-1/3 mt-3 p-2"
            v-for="p in this.projects"
            :key="p.id"
          >
            <vx-card>
              <div class="flex justify-center">
                <img
                  :src="imageCover(p.project_images)"
                  alt="content-img"
                  class="responsive rounded-lg h-64 w-64 justify-center flex"
                />
              </div>
              <div class="my-6">
                <h5 class="mb-2">{{ p.name }}</h5>
              </div>
              <div class="flex justify-between flex-wrap">
                <vs-button
                  class="text-blue-900 mt-4 w-full bg-button-link h-10 rounded-sm p-2 font-normal flex justify-center hover:bg-blue-flattlo hover:text-white hover:shadow"
                  icon-pack="feather"
                  icon="icon-arrow-right"
                  icon-after
                  color="colorx"
                  @click="
                    navigate(p, {
                      name: 'Agregar Propiedades',
                      params: { projectId: `${p.id}` }
                    })
                  "
                  >Editar propiedades
                </vs-button>
                <vs-button
                  class="text-blue-900 mt-4 w-full bg-button-link h-10 rounded-sm p-2 font-normal flex justify-center hover:bg-blue-flattlo hover:text-white hover:shadow"
                  icon-pack="feather"
                  icon="icon-arrow-right"
                  icon-after
                  color="colorx"
                  @click="
                    navigate(p, {
                      name: 'Bodegas',
                      params: { projectId: `${p.id}` }
                    })
                  "
                  >Editar {{ $tc("message.warehouse", 2, user.countryCode) }}
                </vs-button>
                <vs-button
                  class="text-blue-900 mt-4 w-full bg-button-link h-10 rounded-sm p-2 font-normal flex justify-center hover:bg-blue-flattlo hover:text-white hover:shadow"
                  icon-pack="feather"
                  icon="icon-arrow-right"
                  icon-after
                  color="colorx"
                  @click="
                    navigate(p, {
                      name: 'Parqueos',
                      params: { projectId: `${p.id}` }
                    })
                  "
                  >Editar {{ $tc("message.parking", 2, user.countryCode) }}
                </vs-button>
                <vs-button
                  class="text-blue-900 mt-4 w-full bg-button-link h-10 rounded-sm p-2 font-normal flex justify-center hover:bg-blue-flattlo hover:text-white hover:shadow"
                  icon-pack="feather"
                  icon="icon-arrow-right"
                  icon-after
                  color="colorx"
                  @click="
                    navigate(p, {
                      name: 'Galerias',
                      params: { projectId: `${p.id}` }
                    })
                  "
                  >Galerias y presentación
                </vs-button>
                <vs-divider></vs-divider>
                <vs-button
                  v-show="user.role === 'ADMIN'"
                  icon-pack="feather"
                  @click="navigate(p, { name: 'Configuraciones de Proyecto' })"
                  icon="icon-settings"
                  class="mt-4 w-full"
                  color="primary"
                  >Configuraciónes</vs-button
                >
              </div>
            </vx-card>
          </div>
        </div>
      </div>
    </div>
    <div
      id="data-list-thumb-view"
      class="data-list-container"
      v-show="!ShowCards"
    >
      <vs-table
        ref="table"
        single
        v-model="selected"
        pagination
        :max-items="itemsPerPage"
        :data="projects"
        noDataText="🏢 No hay proyectos creados..."
      >
        <div slot="header"></div>
        <template slot="thead">
          <vs-th>Portada Principal</vs-th>
          <vs-th>Proyecto</vs-th>
          <vs-th>Apartamentos</vs-th>
          <vs-th>Etapa</vs-th>
          <vs-th>Ediciones</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
              <vs-td :data="data[index].project_images" class="img-container">
                <img
                  :src="imageCover(data[index].project_images)"
                  class="project_Thumbnail"
                />
              </vs-td>

              <vs-td :data="data[index].name">
                <p class="product-name font-medium">{{ data[index].name }}</p>
              </vs-td>

              <vs-td :data="data[index].apartments_available">
                <p class="product-category">
                  {{ data[index].apartments_available }}
                </p>
              </vs-td>

              <vs-td :data="data[index].stage">
                <vs-chip :color="statusColor(data[index].stage)">{{
                  data[index].project_stage
                    ? data[index].project_stage.name
                    : ""
                }}</vs-chip>
              </vs-td>

              <vs-td :data="data[index].id">
                <vs-row>
                  <vx-tooltip
                    color="primary"
                    text="Editar Propiedades"
                    position="bottom"
                  >
                    <vs-button
                      :disabled="!(selected.id === data[index].id)"
                      color="dark"
                      type="flat"
                      icon-pack="feather"
                      icon="icon-home"
                      class="p-1"
                      @click="
                        navigate(selected, {
                          name: 'Agregar Propiedades',
                          params: { projectId: `${data[index].id}` }
                        })
                      "
                    ></vs-button>
                  </vx-tooltip>
                  <vx-tooltip
                    color="primary"
                    :text="
                      `Editar ${$tc('message.warehouse', 2, user.countryCode)}`
                    "
                    position="bottom"
                  >
                    <vs-button
                      :disabled="!(selected.id === data[index].id)"
                      color="dark"
                      type="flat"
                      icon-pack="feather"
                      icon="icon-package"
                      class="p-1"
                      @click="
                        navigate(selected, {
                          name: 'Bodegas',
                          params: { projectId: `${data[index].id}` }
                        })
                      "
                    ></vs-button>
                  </vx-tooltip>
                  <vx-tooltip
                    color="primary"
                    :text="
                      `Editar ${$tc('message.parking', 2, user.countryCode)}`
                    "
                    position="bottom"
                  >
                    <vs-button
                      :disabled="!(selected.id === data[index].id)"
                      color="dark"
                      type="flat"
                      icon-pack="fas"
                      icon="fa-parking"
                      class="p-1"
                      @click="
                        navigate(selected, {
                          name: 'Parqueos',
                          params: { projectId: `${selected.id}` }
                        })
                      "
                    ></vs-button>
                  </vx-tooltip>
                </vs-row>
                <vs-row>
                  <vx-tooltip
                    color="primary"
                    text="Editar Galerias"
                    position="bottom"
                  >
                    <vs-button
                      :disabled="!(selected.id === data[index].id)"
                      color="dark"
                      type="flat"
                      icon-pack="feather"
                      icon="icon-image"
                      class="p-1"
                      @click="
                        navigate(selected, {
                          name: 'Galerias',
                          params: { projectId: `${selected.id}` }
                        })
                      "
                    ></vs-button>
                  </vx-tooltip>
                  <vx-tooltip
                    color="primary"
                    text="Configuraciónes"
                    position="bottom"
                  >
                    <vs-button
                      :disabled="!(selected.id === data[index].id)"
                      color="dark"
                      type="flat"
                      icon-pack="feather"
                      icon="icon-settings"
                      class="p-1"
                      @click="
                        navigate(selected, {
                          name: 'Configuraciones de Proyecto'
                        })
                      "
                    ></vs-button>
                  </vx-tooltip>
                </vs-row>
                <vs-row></vs-row>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
import { api } from "../services";
import { mapState } from "vuex";
import { sentryCaptureException } from "../../../helpers/Sentry";

export default {
  name: "ListadoProyectos",
  data() {
    return {
      projects: [],
      selected: [],
      itemsPerPage: 8,
      ShowCards: true
    };
  },
  computed: {
    ...mapState("auth", ["user"])
  },
  methods: {
    navigate(project, route) {
      try {
        const projectId = project.id;
        const currencySymbol = project.country_currency
          ? project.country_currency.currency.symbol
          : null;

        this.$store.dispatch("project/currentProject", { projectId });
        this.$store.dispatch("project/currencySymbol", currencySymbol);

        this.$router.push(route);
      } catch (e) {
        sentryCaptureException(e, "projects");
      }
    },
    statusColor(status) {
      if (status == "BUILDING") return "success";
      if (status == "LAUNCHING") return "success";
    },
    imageCover(images) {
      const coverImages = images.filter(
        item => item.project_image_gallery === "COVER"
      );
      if (!coverImages.length) {
        return null;
      }
      const image = coverImages[0].img_url;
      return image;
    }
  },
  async mounted() {
    try {
      const devId = this.$store.getters["auth/getUser"].developerId;
      this.projects = await api.listProjects(devId);
    } catch (e) {
      sentryCaptureException(e, "projects");
    }
  }
};
</script>

<style lang="scss">
.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y {
  position: absolute;
  background-color: #f5e037;
}
#data-list-thumb-view {
  .vs-con-table {
    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 0px;
      margin-right: 0px;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
          &.img-container {
            // width: 1rem;
            // background: #fff;

            span {
              display: flex;
              justify-content: left;
            }

            .project_Thumbnail {
              height: 110px;
            }
          }
        }
        td.td-check {
          padding: 10px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
    .dropdown-button-container {
      display: flex;
      align-items: center;

      .btnx {
        border-radius: 5px 0px 0px 5px;
      }

      .btn-drop {
        border-radius: 0px 5px 5px 0px;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
  }
}

.bg-button-link {
  background-color: #dbeafe;
  color: #448aff;
}
</style>
