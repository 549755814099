var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-end"}},[_c('vs-row',[_c('vs-button',{staticClass:"m-1 mb-4",attrs:{"color":"success","type":"filled","icon-pack":"feather","icon":"icon-plus","to":"/formulario-de-ingreso-de-proyecto"}},[_vm._v("Proyecto")])],1),_c('vs-tabs',{staticClass:"p-1",attrs:{"alignment":"right","color":"primary"}},[_c('vs-tab',{attrs:{"label":"Tarjetas","icon-pack":"feather","icon":"icon-grid"},on:{"click":function($event){_vm.ShowCards = true}}}),_c('vs-tab',{attrs:{"selected":"true","label":"Lista","icon-pack":"feather","icon":"icon-list"},on:{"click":function($event){_vm.ShowCards = false}}})],1)],1)],1),_c('br'),_c('br'),_c('br'),_c('br'),_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ShowCards),expression:"ShowCards"}]},[_c('div',{staticClass:"flex flex-wrap sm:full lg:w-full mt-1"},_vm._l((this.projects),function(p){return _c('div',{key:p.id,staticClass:"w-full sm:w-1/2 md:w-3/3 lg:w-1/5 xl:w-1/3 mt-3 p-2"},[_c('vx-card',[_c('div',{staticClass:"flex justify-center"},[_c('img',{staticClass:"responsive rounded-lg h-64 w-64 justify-center flex",attrs:{"src":_vm.imageCover(p.project_images),"alt":"content-img"}})]),_c('div',{staticClass:"my-6"},[_c('h5',{staticClass:"mb-2"},[_vm._v(_vm._s(p.name))])]),_c('div',{staticClass:"flex justify-between flex-wrap"},[_c('vs-button',{staticClass:"text-blue-900 mt-4 w-full bg-button-link h-10 rounded-sm p-2 font-normal flex justify-center hover:bg-blue-flattlo hover:text-white hover:shadow",attrs:{"icon-pack":"feather","icon":"icon-arrow-right","icon-after":"","color":"colorx"},on:{"click":function($event){return _vm.navigate(p, {
                    name: 'Agregar Propiedades',
                    params: { projectId: `${p.id}` }
                  })}}},[_vm._v("Editar propiedades\n              ")]),_c('vs-button',{staticClass:"text-blue-900 mt-4 w-full bg-button-link h-10 rounded-sm p-2 font-normal flex justify-center hover:bg-blue-flattlo hover:text-white hover:shadow",attrs:{"icon-pack":"feather","icon":"icon-arrow-right","icon-after":"","color":"colorx"},on:{"click":function($event){return _vm.navigate(p, {
                    name: 'Bodegas',
                    params: { projectId: `${p.id}` }
                  })}}},[_vm._v("Editar "+_vm._s(_vm.$tc("message.warehouse", 2, _vm.user.countryCode))+"\n              ")]),_c('vs-button',{staticClass:"text-blue-900 mt-4 w-full bg-button-link h-10 rounded-sm p-2 font-normal flex justify-center hover:bg-blue-flattlo hover:text-white hover:shadow",attrs:{"icon-pack":"feather","icon":"icon-arrow-right","icon-after":"","color":"colorx"},on:{"click":function($event){return _vm.navigate(p, {
                    name: 'Parqueos',
                    params: { projectId: `${p.id}` }
                  })}}},[_vm._v("Editar "+_vm._s(_vm.$tc("message.parking", 2, _vm.user.countryCode))+"\n              ")]),_c('vs-button',{staticClass:"text-blue-900 mt-4 w-full bg-button-link h-10 rounded-sm p-2 font-normal flex justify-center hover:bg-blue-flattlo hover:text-white hover:shadow",attrs:{"icon-pack":"feather","icon":"icon-arrow-right","icon-after":"","color":"colorx"},on:{"click":function($event){return _vm.navigate(p, {
                    name: 'Galerias',
                    params: { projectId: `${p.id}` }
                  })}}},[_vm._v("Galerias y presentación\n              ")]),_c('vs-divider'),_c('vs-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.user.role === 'ADMIN'),expression:"user.role === 'ADMIN'"}],staticClass:"mt-4 w-full",attrs:{"icon-pack":"feather","icon":"icon-settings","color":"primary"},on:{"click":function($event){return _vm.navigate(p, { name: 'Configuraciones de Proyecto' })}}},[_vm._v("Configuraciónes")])],1)])],1)}),0)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.ShowCards),expression:"!ShowCards"}],staticClass:"data-list-container",attrs:{"id":"data-list-thumb-view"}},[_c('vs-table',{ref:"table",attrs:{"single":"","pagination":"","max-items":_vm.itemsPerPage,"data":_vm.projects,"noDataText":"🏢 No hay proyectos creados..."},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return [_c('tbody',_vm._l((data),function(tr,index){return _c('vs-tr',{key:index,attrs:{"data":tr}},[_c('vs-td',{staticClass:"img-container",attrs:{"data":data[index].project_images}},[_c('img',{staticClass:"project_Thumbnail",attrs:{"src":_vm.imageCover(data[index].project_images)}})]),_c('vs-td',{attrs:{"data":data[index].name}},[_c('p',{staticClass:"product-name font-medium"},[_vm._v(_vm._s(data[index].name))])]),_c('vs-td',{attrs:{"data":data[index].apartments_available}},[_c('p',{staticClass:"product-category"},[_vm._v("\n                "+_vm._s(data[index].apartments_available)+"\n              ")])]),_c('vs-td',{attrs:{"data":data[index].stage}},[_c('vs-chip',{attrs:{"color":_vm.statusColor(data[index].stage)}},[_vm._v(_vm._s(data[index].project_stage
                  ? data[index].project_stage.name
                  : ""))])],1),_c('vs-td',{attrs:{"data":data[index].id}},[_c('vs-row',[_c('vx-tooltip',{attrs:{"color":"primary","text":"Editar Propiedades","position":"bottom"}},[_c('vs-button',{staticClass:"p-1",attrs:{"disabled":!(_vm.selected.id === data[index].id),"color":"dark","type":"flat","icon-pack":"feather","icon":"icon-home"},on:{"click":function($event){return _vm.navigate(_vm.selected, {
                        name: 'Agregar Propiedades',
                        params: { projectId: `${data[index].id}` }
                      })}}})],1),_c('vx-tooltip',{attrs:{"color":"primary","text":`Editar ${_vm.$tc('message.warehouse', 2, _vm.user.countryCode)}`,"position":"bottom"}},[_c('vs-button',{staticClass:"p-1",attrs:{"disabled":!(_vm.selected.id === data[index].id),"color":"dark","type":"flat","icon-pack":"feather","icon":"icon-package"},on:{"click":function($event){return _vm.navigate(_vm.selected, {
                        name: 'Bodegas',
                        params: { projectId: `${data[index].id}` }
                      })}}})],1),_c('vx-tooltip',{attrs:{"color":"primary","text":`Editar ${_vm.$tc('message.parking', 2, _vm.user.countryCode)}`,"position":"bottom"}},[_c('vs-button',{staticClass:"p-1",attrs:{"disabled":!(_vm.selected.id === data[index].id),"color":"dark","type":"flat","icon-pack":"fas","icon":"fa-parking"},on:{"click":function($event){return _vm.navigate(_vm.selected, {
                        name: 'Parqueos',
                        params: { projectId: `${_vm.selected.id}` }
                      })}}})],1)],1),_c('vs-row',[_c('vx-tooltip',{attrs:{"color":"primary","text":"Editar Galerias","position":"bottom"}},[_c('vs-button',{staticClass:"p-1",attrs:{"disabled":!(_vm.selected.id === data[index].id),"color":"dark","type":"flat","icon-pack":"feather","icon":"icon-image"},on:{"click":function($event){return _vm.navigate(_vm.selected, {
                        name: 'Galerias',
                        params: { projectId: `${_vm.selected.id}` }
                      })}}})],1),_c('vx-tooltip',{attrs:{"color":"primary","text":"Configuraciónes","position":"bottom"}},[_c('vs-button',{staticClass:"p-1",attrs:{"disabled":!(_vm.selected.id === data[index].id),"color":"dark","type":"flat","icon-pack":"feather","icon":"icon-settings"},on:{"click":function($event){return _vm.navigate(_vm.selected, {
                        name: 'Configuraciones de Proyecto'
                      })}}})],1)],1),_c('vs-row')],1)],1)}),1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('div',{attrs:{"slot":"header"},slot:"header"}),_c('template',{slot:"thead"},[_c('vs-th',[_vm._v("Portada Principal")]),_c('vs-th',[_vm._v("Proyecto")]),_c('vs-th',[_vm._v("Apartamentos")]),_c('vs-th',[_vm._v("Etapa")]),_c('vs-th',[_vm._v("Ediciones")])],1)],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }