<template>
    <ProjectsList />
</template>

<script>
import ProjectsList from '../components/ProjectsList'

export default {
    components:{
        ProjectsList,
    },
    async mounted() {
        this.$store.state.project.breadcrumbs = [{ pageName: 'Edición de proyectos', route: '/proyectos' }];
    }
}
</script>
